import { useMutation } from '@apollo/react-hooks';
import { publicClient } from 'application/middleware/ekardo/ekardoClient';
import cx from 'classnames';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Header from 'components/Header';
import LoadingSpinner from 'components/LoadingSpinner';
import gql from 'graphql-tag';
import withApollo from 'lib/apollo';
import { useIsAuthenticated } from 'lib/hooks/useIsAuthenticated';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import styles from './indexPage.module.css';

const NoSSRContentWrapper = dynamic<{trackingId: number; pageId: number;}>(() => import('../components/ContentUI/NoSSRComp'), {
  ssr: false,
});

const GET_USEFLOW_STEP_TRACK = gql`
  mutation {
    userFlowStepTrack {
      startUserFlowStep(stepId: 145) {
        id
        answers {
          contentSnippetQuestionId
        }
      }
    }
  }
`;

function Index() {
  const isAuthenticated = useIsAuthenticated();

  const [trackingId, setTrackingId] = useState<number>(undefined);
  const [isQuizAlreadyCompleted, setIsQuizAlreadyCompleted] = useState<boolean>(false);

  const [executeGetUserFlowStepTrack, { loading: isGetUserFlowStepTrackLoading }] = useMutation(GET_USEFLOW_STEP_TRACK, {
    client: publicClient,
    onCompleted(response) {
      const {
        userFlowStepTrack: {
          startUserFlowStep: { answers = [], id: trackingId },
        },
      } = response;

      if (answers.find((answer) => answer.contentSnippetQuestionId === 14493)) {
        setIsQuizAlreadyCompleted(true);
        return;
      }

      setTrackingId(trackingId);
    },
  });

  useEffect(() => {
    executeGetUserFlowStepTrack();
  }, []);

  return (
    <>
      {isGetUserFlowStepTrackLoading && <LoadingSpinner />}
      <Head>
        <title>Crystal Clear</title>
      </Head>
      <main className={styles['main']}>
        <div className={styles['Layout']}>
          <Header showMenu={isAuthenticated} />
          <section className={cx(styles['program-section'], styles['section'])}>
            <div className="h-container--small">
              <div className={cx(styles['program-section__content'], 'h-fadein')}>
                <h1 className="h-color--white h-font--uppercase">
                  Welcome to <br />
                  <span style={{ color: 'var(--pale)' }}>Crystal </span>
                  <span style={{ color: 'var(--pale) ', fontWeight: 100 }}>clear</span>
                </h1>
                <p className="h-color--white m-b--large">
                  Helping you become more aware of how your current lifestyle and your use of ice affects your health and other areas of
                  your life.
                </p>
                <Button component="link" color="secondary-inverse" size="large" style="squared" link={{ href: '/about' }}>
                  Learn more
                </Button>
              </div>
            </div>
          </section>

          <section className={cx(styles['about-section'], styles['section'])}>
            <div className="h-container--small">
              <div className={cx(styles['about-section__content'], 'h-fadein')}>
                <h2 className="h1 h-font--uppercase">
                  About the <br />
                  <span style={{ fontWeight: 100 }}>program</span>
                </h2>
                <p>
                  The Crystal Clear program is designed to help you become more aware of how your current lifestyle and your use of ice
                  affects your health and other areas of your life. It will also help you to weigh up the important issues or problems you
                  have identified. In this program you will also be given information about where you can get information and help to make
                  some changes if that is what you decide to do.
                </p>
              </div>
            </div>
          </section>

          <section className={cx(styles['quiz-section'], styles['section'])}>
            <div className="h-container--small">
              <div className="h-fadein">
                {isQuizAlreadyCompleted && (
                  <>
                    <h2 className="h1 m-b h-font--uppercase">
                      <>You already completed the eligibility quiz</>
                    </h2>
                  </>
                )}
                {!isQuizAlreadyCompleted && (
                  <>
                    <h2 className="h1 m-b h-font--uppercase">
                      <>
                        Not sure if this program is
                        <br />
                        <span style={{ fontWeight: 100 }}>right for you?</span>
                      </>
                    </h2>
                    <p className="m-b--large">Take the quiz to see if this program is right for you.</p>
                  </>
                )}
                {trackingId && <NoSSRContentWrapper trackingId={Number(trackingId)} pageId={105685} />}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default withApollo(Index, { useTemporaryToken: true });
