import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import ApolloClient from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../../../fragmentTypes.json';
import fetch from 'node-fetch';
import { GetTemporaryToken, _GetAccessToken } from '@netfront/gelada-react-shared';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const httpLink = createHttpLink({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_EKARDO_API_URL,
  fetch: fetch as any,
});

const authLink = setContext((_, { headers }) => {
  const token = _GetAccessToken();
  const header = token
    ? {
        ...headers,
        authorization: token,
      }
    : {
        ...headers,
      };
  return {
    headers: header,
  };
});

const authLinkTemp = setContext((_, { headers }) => {
  const token = GetTemporaryToken();
  const header = token
    ? {
        ...headers,
        authorization: token,
      }
    : {
        ...headers,
      };
  return {
    headers: header,
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export const publicClient = new ApolloClient({
  link: authLinkTemp.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }),
});
